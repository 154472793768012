<template>
    <div class="examine-wrraper" @click="close">
        <div class="item-examine">
            <div class="title">
                <div class="close">
                    <i class="material-icons" style="cursor:pointer;" @click="close_x">close</i>
                </div>
                {{product.name}}
            </div>
            <div class="product-image">
                <div class="makat">
                    <p>{{product.makat}}</p>
                </div>
                <div class="uploading" v-if="uploading">
                    <i class="el-icon-loading"></i>
                    <p>עורך ומעלה תמונה חדשה</p>
                </div>
                <img :src="product.image" alt="" @error="fix_image"/>
                <input type="file" name="" id="upload" @change="upload_image_db" class="upload-image"/>

                <div class="search-image" @click="search_image">
                    <i class="material-icons" style="cursor:pointer;">search</i>
                </div>
            </div>
            <div class="product-data">
                <div class="right-side">
                    <p class="header">שם חילופי לפריט</p>
                    <div class="text-n-go">
                        <el-input placeholder="הכנס שם חילופי" v-model="product.new_name" class="text-n-go-input"/>
                        <el-button @click="handle_update_property('שם חלופי')" type="success" icon="el-icon-check" class="text-n-go-btn"></el-button>
                    </div>

                    <p class="header">מחיר קבוע לפריט</p>
                    <div class="text-n-go">
                        <el-input placeholder="הכנס מחיר קבוע" v-model="product.fixed_price" class="text-n-go-input"/>
                        <el-button  @click="handle_update_property('מחיר קבוע')" type="success" icon="el-icon-check" class="text-n-go-btn"></el-button>
                    </div>

                    <p class="header">תאריך תוקף לפריט</p>
                    <div class="text-n-go">
                        <el-input placeholder="הכנס תאריך תוקף" v-model="product.expiry_date" class="text-n-go-input"/>
                        <el-button @click="handle_update_property('תאריך תוקף')" type="success" icon="el-icon-check" class="text-n-go-btn"></el-button>
                    </div>

                    <p class="header">מסגרת לפריט</p>
                    <div class="text-n-go">
                        <el-select v-model="product.frame" class="m-2 text-n-go-input" placeholder="בחר מסגרת לפריט" size="large">
                        <el-option
                            v-for="frame in frames_options"
                            :key="frame"
                            :value="frame"
                        />
                    </el-select>
                        <el-button @click="handle_update_property('מסגרת')" type="success" icon="el-icon-check" class="text-n-go-btn"></el-button>
                    </div>

                </div>
                <div class="left-side">
                    <p class="header">קטגוריה</p>
                    <div class="text-n-go">
                        <el-select v-model="product.category" placeholder="בחר קטגוריה ראשית" class="text-n-go-input" filterable>
                            <el-option v-for="department in departments" :key="department.uid" :label="department.name" :value="department.uid"/>
                        </el-select>
                        <el-button type="success" icon="el-icon-check" class="text-n-go-btn" @click="update_product_category(product)"></el-button>
                    </div>
                    <p class="header">תת קטגוריה</p>
                    <div class="text-n-go">
                        <el-select v-model="product.sub_category" placeholder="בחר תת קטגוריה" class="text-n-go-input" filterable>
                            <el-option v-for="sub in subs" :key="sub" :label="sub" :value="sub"/>
                        </el-select>
                        <el-button type="success" icon="el-icon-check" class="text-n-go-btn" @click="update_product_sub_category(product)"></el-button>
                    </div>
                    <p class="header">הערות <span style="color:red;">מוגבל ל-20 תווים</span></p>
                    <div class="text-n-go">
                        <el-input class="text-n-go-input" v-model="product.note" placeholder="הערות לפריט" />
                        <el-button v-model="product" type="success" icon="el-icon-check" class="text-n-go-btn" @click="handle_update_property('הערה')"></el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { projectFirestore, projectFunctions } from '../../../firebase/config';
import { slide_pop_error, slide_pop_successs } from '../../../Methods/Msgs';
import {upload_product_image} from '../../../Methods/Storage';
import { computed} from '@vue/runtime-core';
import store from '../../../store';

export default {
emits:["close", "update_product"],
props: ["product"],
setup(props, {emit}){

    const frames_options = ref([
        'אדום',
        'ירוק',
        'כחול'
    ])
    const uploading = ref(false);
    const departments = ref(computed(() => {
        return store.getters.admin_departments
    }));

    if(!departments.value){
        store.dispatch('get_departments_admin')
    }
    const handle_update_property = async(type)=>{
        switch (type) {
            case 'שם חלופי':
                if(!props.product.new_name){
                    props.product.new_name = null
                }
                await update_product_by_type('new_name')
                slide_pop_successs("שם חלופי עודכן בהצלחה!")
                break;
            case 'מחיר קבוע':
                if(!props.product.fixed_price){
                    props.product.fixed_price = null
                }
                await update_product_by_type('fixed_price')
                slide_pop_successs("מחיר קבוע עודכן בהצלחה!")
                break;
            case 'תאריך תוקף':
                if(!props.product.expiry_date){
                    props.product.expiry_date = null
                }
                await update_product_by_type('expiry_date')
                 slide_pop_successs("תאריך תוקף עודכן בהצלחה!")
                break;
            case 'הערה':
                if(!props.product.expiry_date){
                    props.product.expiry_date = null
                }
                await update_product_by_type('note')
                 slide_pop_successs("הערה לפריט עודכנה בהצלחה!")
                break;
            case 'מסגרת':
                if(!props.product.frame){
                    props.product.frame = null
                }
                await update_product_by_type('frame')
                 slide_pop_successs("מסגרת לפריט עודכנה בהצלחה!")
                break;
            default:
                break;
        }
    }

    const update_product_by_type = async(type)=>{
        let value
        if(type=='new_name'){
            value = props.product.new_name
        }
        if(type=='fixed_price'){
            value = props.product.fixed_price
        }
        if(type=='expiry_date'){
            value = props.product.expiry_date
        }
        if(type=='note'){
            value = props.product.note
        }
        if(type=='frame'){
            value = props.product.frame
        }
        await projectFirestore.collection('Products').doc(props.product.makat).set({
            [type] : value
        },{merge:true})
    }

    const subs = ref(computed(() => {
        if(props.product.category && props.product.category.length >= 20 && departments.value){
            let inx = departments.value.findIndex(cat => {
                return cat.uid == props.product.category
            }) 
            if(inx != -1){
                return departments.value[inx].subs
            }
        }
        return ''
    }))
    
    const close = (e) => {
        if(e.srcElement.className.includes('examine-wrraper')){
            emit("close")
        }
    }
    const close_x = (e) => {
        emit("close")
    }
    const fix_image = (e) => {
        e.target.src = 'https://cdn.iconscout.com/icon/free/png-512/no-image-1771002-1505134.png';
        slide_pop_error('שים לב, חסרה למוצר זה תמונה!')
    }

    const init_upload = () => {
        const el = document.getElementById('upload');
        if(el){
            el.click();
        }
    }

    const upload_image_db = async (e) => {
        console.log(e);
        console.log('Uploading!');
        uploading.value = true;
        let file = e.target.files[0]
        const url = await upload_product_image(props.product.makat, file)
        props.product.image = url;
        slide_pop_successs('התמונה עודכנה בהצלחה!');
        uploading.value = false;
        await projectFunctions.httpsCallable('upload_product_image')({url: url, makat: props.product.makat})
        if(props.product.errors){
            let inx = props.product.errors.findIndex(err => {
                return err == 'תמונה'
            }) 
            if(inx != -1){
                props.product.errors.splice(inx, 1);
            }
        }
    }

    const search_image = () => {
        window.open(`https://www.google.com/search?q=${props.product.barcode}`)
    }

    const update_product_category = async (item) => {
        if(item.errors){
            let inx = item.errors.findIndex(err => {
                return err == 'קטגוריה'
            })
            if(inx != -1){
                item.errors.splice(inx, 1);
            }
        }
        await projectFunctions.httpsCallable('update_product_category')({makat: item.makat, category: item.category})
        slide_pop_successs('קטגורית פריט עודכנה בהצלחה!')
    }
    
    const update_product_sub_category = async (item) => {
        if(item.errors){
            let inx = item.errors.findIndex(err => {
                return err == 'תת קטגוריה'
            })
            if(inx != -1){
                item.errors.splice(inx, 1);
            }
        }
        await projectFunctions.httpsCallable('update_product_sub_category')({makat: item.makat, sub_category: item.sub_category})
        slide_pop_successs('משפחת פריט עודכנה בהצלחה!')
    }

    return{
        close, close_x, fix_image, init_upload, upload_image_db, uploading, departments,
        subs, search_image, update_product_category,update_product_sub_category,
        handle_update_property,frames_options

    }
}
}
</script>

<style scoped>
.examine-wrraper{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.611);
    z-index: 1;
}
.item-examine{
    width: 650px;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: whitesmoke;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.527);
    z-index: 2;
    overflow-y: auto;
}
.title{
    position: sticky;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
    color: black;
    z-index: 2;
    background: whitesmoke;
}
.close{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--light);
    background: var(--danger);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.416);
}
.product-image{
    position: relative;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}
.product-image img{
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.049);
    border-radius: 10px;
}
.upload-image{
    position: absolute;
    width: 150px;
    height: 50px;
    background: var(--purple);
    border-radius: 10px;
    bottom: 10px;
    left: 30%;
    transform: translateX(-30%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.149);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light);
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.349);
    cursor: pointer;
    transition: 0.25s;
}
.search-image{
    position: absolute;
    width: 50px;
    height: 50px;
    background: var(--purple);
    border-radius: 10px;
    bottom: 10px;
    left: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.149);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light);
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.349);
    cursor: pointer;
    transition: 0.25s;
}
.upload-image:hover{
    background: var(--success);
}
.uploading{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.486);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--light);
    text-shadow: 0 0 5px rgb(0, 0, 0);
    font-size: 18px;
}
.uploading i{
    font-size: 40px;
    margin-bottom: 15px;
}
.makat{
    position: absolute;
    top: 10px;
    right: 10px;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    text-align: center;
    font-size: 28px;
    background: var(--purple);
    border-radius: 5px;
    color:white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.549);
}
.product-data{
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}
.header{ 
    width: 100%;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin-top: 5px;
}
.text-n-go{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 0 0 0 5px;
}
.text-n-go-input{
    width: 78%;
    height: 100%;
    padding: 0px 5px 0 0px;
}
.text-n-go-btn{
    width:20%;
}
@media only screen and (max-width: 800px) {
    .item-examine{
        width: 95%;
        height: 90%;
    }
    .product-data{
        grid-template-columns: 100%;
        grid-template-rows: max-content max-content;
    }
}


.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

</style>